import jsPDF from 'jspdf'

interface IColumn {
    header:string,
    dataKey:string
}
const textoCentrado = (doc: any, text: string, alto: number): any => {
    const textWidth = (doc.getStringUnitWidth(text) * doc.internal.getFontSize()) / doc.internal.scaleFactor
    const textOffset = (doc.internal.pageSize.width - textWidth) / 2
    return doc.text(textOffset, alto, text)
  }
export const exportTablePDF = (data:any, columnsPDF:IColumn[], nombrePDf:string, tamañoHoja=[850,900], fontSize=9  ): void => {
    const document: any = new jsPDF('l', 'pt', tamañoHoja)
    document.setFontSize(14)
    textoCentrado(document, nombrePDf, 20)
    document.setFont('helvetica', 'italic')
    document.setFontSize(10)
    document.autoTable({
      startY: 50,
      body: data,
      columns: columnsPDF,
      styles: { fontSize: fontSize },
      headStyles: { fillColor: [186, 0, 0] }
    })
    document.save(nombrePDf + '.pdf')
}

const existKeys = (items:any[], labelKey:string,valueKey:string):boolean=>{
  return items.every(item =>{
    const keys = Object.keys(item)
    return keys.includes(labelKey) && keys.includes(valueKey)
  })
}

export const parseToSelectOption = (items:any[], labelKey:string,valueKey:string):{label:string,value:any}[] =>{
    if(existKeys(items,labelKey,valueKey)){
      return items.map((item)=>({label:item[labelKey], value:item[valueKey]}))
    }else{
      throw new Error('keys not exists')
    }
}

export const filterOptions = (input:any, option: any) => (option?.label ?? '').toLowerCase().includes(input.toLowerCase())

export function filtrarJSON(jsonInput: { [key: string]: any }): { [key: string]: any } {
  const filteredJSON: { [key: string]: any } = {};

  for (const key in jsonInput) {
    const value = jsonInput[key];

    if (value !== null && value !== undefined) {
      if (typeof value === 'string' && value.trim() !== '') {
        filteredJSON[key] = value;
      } else if (Array.isArray(value) && value.length > 0) {
        filteredJSON[key] = value;
      } else if (typeof value !== 'string' && !Array.isArray(value)) {
        filteredJSON[key] = value;
      }
    }
  }
  return filteredJSON;
}